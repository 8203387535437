$white: #fff
$black: #000

$nm-gray-50: #fafafa
$nm-gray-100: #f5f5f5
$nm-gray-200: #ebebeb
$nm-gray-300: #d1d1d1
$nm-gray-400: #a5a5a5
$nm-gray-500: #737373
$nm-gray-600: #575757
$nm-gray-700: #474747
$nm-gray-800: #333333
$nm-gray-900: #171717

$nm-yellow-50: #fcf1ca
$nm-yellow-100: #fceba4
$nm-yellow-200: #fcdf7e
$nm-yellow-300: #fcd34c
$nm-yellow-400: #fdc300
$nm-yellow-500: #ebac00
$nm-yellow-600: #cc9629
$nm-yellow-700: #b37d22
$nm-yellow-800: #805915
$nm-yellow-900: #4d3b0a
