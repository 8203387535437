@import _nm-colors

$main-color: $nm-yellow-400
$bg-color: rgb(28, 28, 30)
$text-color: rgba(255, 255, 255, 0.9)
$on-main-color: $text-color

$btn-text-color: black

$title-bar: rgb(56, 56, 64)
$title-bar-hover: rgb(94, 94, 107)
$font-family: "Open Sans", Arial, Helvetica, sans-serif
$fa: "Font Awesome 5 Pro"

$light-grey: rgb(130, 130, 130)

$green: rgb(0, 128, 0)
$light-green: rgb(0, 182, 0)
$dark-green: rgb(0, 100, 0)

$red: rgb(255, 0, 0)
$dark-red: rgb(139, 0, 0)

$orange: rgb(255, 141, 0)

/* timer vars */
$inactive: rgba(255, 255, 255, 0.22)

:root
    --bg-color: #{$bg-color}
    --inactive: #{$inactive}
    --active: #{$green}
    --warn: #{$orange}
    --time-out: #{$red}
    --text-color: #{$text-color}
    --blink: #{$dark-red}
