@import _vars

$timer-width: 80vw
$time-size: 6vw
$date-size: 3.2vw

.timerScreen
    background-color: var(--bg-color)
    color: var(--text-color)

    .logo
        display: flex
        justify-content: center
        position: fixed
        bottom: 20px
        right: 20px
        img
            width: 160px

    .timer
        color: $green
        -webkit-transition-duration: 0.4s
        transition-duration: 0.4s

    .timer,
    .time.center
        position: fixed
        top: 21vh
        width: 100%
        text-align: center
        margin-left: unset
        font-size: 16vw
        font-weight: 600

    .dateTime
        position: absolute
        bottom: 165px
        width: 100%
        display: flex
        justify-content: space-between
        align-items: baseline

        &.center
            justify-content: center
            .time,
            .date
                margin: unset

    .time
        margin-left: 4vw
        font-size: $time-size

    .date
        margin-right: 4vw
        font-size: $date-size
    .dots
        -webkit-transition-duration: 0.8s
        transition-duration: 0.8s

    .inactive
        color: var(--inactive) !important

    .timeUp
        color: var(--text-color) !important

    .timeOver
        color: var(--time-out) !important

    .timeWarn
        color: var(--warn) !important

    .blink
        color: transparent

    @mixin keyframes($duration)
        $name: keyframe-#{unique-id( )}
        animation-name: $name
        animation-duration: $duration
        animation-iteration-count: infinite
        @keyframes #{$name}
            @content

    &.blinkScreen
        @include keyframes(1s )
            #{50%}
                background-color: var(--blink)
