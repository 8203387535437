
// Checkboxes
.checkbox
    position: relative
    z-index: 0
    float: left
    margin: 0
    appearance: none

    & + label
        margin-left: 2px

        &:before
            font-family: $fa
            color: $main-color
            display: inline-block
            position: relative
            content: "\f0c8"
            font-size: 1.1rem
            z-index: 5
            margin: 0 5px 0 0

    &:checked + label
        &:before
            content: "\f14a"

    &:disabled + label
        cursor: not-allowed
        color: $nm-gray-600
