@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%))
    &::-webkit-scrollbar
        width: $size
        height: $size

    &::-webkit-scrollbar-thumb
        background: $foreground-color
        border-radius: 3px

    &::-webkit-scrollbar-track
        background: $background-color
        border-radius: 3px

    &
        scrollbar-face-color: $foreground-color
        scrollbar-track-color: $background-color
