table
    width: 100%
    border-collapse: collapse
    margin: 25px 0
    font-size: 0.9em

    thead
        tr, th
            border-bottom: solid $nm-yellow-400
            text-align: left

    th,
    td
        padding: 12px 15px

    tbody
        tr

            &:nth-of-type(even)
                background-color: #3c3c3c

            &:hover
                background-color: #2c2c2c

@mixin table-portrait
    table
        .wide
            display: none

@mixin table-portrait-ipad
@mixin table-landscape-smartphone
