#dropArea
    border: 2px dashed $main-color
    border-radius: 20px
    width: 270px
    margin: 0 auto
    padding: 20px
    display: flex
    flex-direction: column
    align-items: center

    &.highlight
        background-color: #fdc20071

    p
        margin-top: 0
        text-align: center

    input
        display: none

    @mixin progressAnimation($duration)
        $name: keyframe-#{unique-id( )}
        animation-name: $name
        animation-duration: $duration
        animation-iteration-count: infinite
        animation-timing-function: linear
        @keyframes #{$name}
            @content

    #progressBars
        width: 100%
        .progress-bar
            height: 20px
            width: 100%
            margin-bottom: 20px
            overflow: hidden
            background-color: $on-main-color
            border-radius: 4px
            box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%)

            .progress
                float: left
                width: 0%
                height: 100%
                font-size: 12px
                line-height: 20px
                color: black
                text-align: center
                background-color: $main-color
                box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%)
                transition: width .6s ease
                background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent)
                background-size: 40px 40px
                &.active
                    @include progressAnimation(2s)
                        #{from}
                            background-position: 40px 0
                            #{to}
                                background-position: 0 0
                &.success
                    background-color: $green
                    background-image: none
                    color: white

                &.error
                    background-color: $red
                    background-image: none
                    color: white
