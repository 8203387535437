
.presenterFiles
    #container
        margin: 5vh 10vw
    #header
        display: flex
        vertical-align: middle
        font-size: 2rem
        margin-bottom: 30px
        img
            width: 40px
            margin-right: 10px
    th[data-sort]
        cursor: pointer
        &::after
            font-family: "Font Awesome 5 Pro"
            font-size: 1rem
            content: ' \f883'
        &[data-sortorder="asc"]::after
            content: ' \f15d'
        &[data-sortorder="desc"]::after
            content: ' \f882'
